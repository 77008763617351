import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import Layout from './components/layouts/Layout/Layout.jsx';
import Loader from './components/utils/Loader.jsx';
import { login, logout } from './features/auth.js';
import AppConfig from './routes/admin/app-config.jsx';
import Call from './routes/admin/call.jsx';
import ConfigConversationType from './routes/admin/conversation-type/config.jsx';
import ContactsFilter from './routes/admin/conversation-type/contacts.jsx';
import CreateEditConversationType from './routes/admin/conversation-type/create-edit.jsx';
import ConversationTypesList from './routes/admin/conversation-type/list.jsx';
import Hello from './routes/admin/hello.jsx';
import CreateEditMessagingAccount from './routes/admin/messaging-accounts/create-edit.jsx';
import MessagingAccountsList from './routes/admin/messaging-accounts/list.jsx';
import ConversationCalculator from './routes/admin/pricing/conversation.jsx';
import CreateUserMessagingAccount from './routes/admin/user-messaging-accounts/create.jsx';
import UserMessagingAccountsList from './routes/admin/user-messaging-accounts/list.jsx';
import WebhookSimulationTool from './routes/admin/webhook-simulation-tool.jsx';
import ConversationList from './routes/conversation/list.jsx';
import NewConversation from './routes/conversation/new.jsx';
import ViewConversation from './routes/conversation/view.jsx';
import ProtectedRoute from './routes/protected-route.jsx';
import ConnectMetaPage from './routes/user/connect-meta-page.jsx';
import Login from './routes/user/login.jsx';
import MetaInvite from './routes/user/meta-invite.jsx';
import MetaApiLoginRegisterRedirect from './routes/user/meta-login-register-redirect.jsx';
import Onboarding from './routes/user/onboarding.jsx';
import Register from './routes/user/register.jsx';
import SetPassword from './routes/user/set-password.jsx';
import Settings from './routes/user/settings.jsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <NewConversation />,
      },
      {
        path: '/conversation',
        element: <Outlet />,
        children: [
          {
            path: 'new',
            element: <NewConversation />,
          },
          {
            path: ':uuid',
            element: <ViewConversation />,
          },
          {
            element: <ProtectedRoute requiredRole="NORMAL" />,
            children: [
              {
                path: 'list',
                element: <ConversationList />,
              },
            ],
          },
        ],
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/set-password',
        element: <SetPassword />,
      },
      {
        path: '/user',
        element: <Outlet />,
        children: [
          {
            path: 'connect-meta-page',
            element: <ConnectMetaPage />,
          },
          {
            path: 'meta-login-register-redirect',
            element: <MetaApiLoginRegisterRedirect />,
          },
          {
            path: 'meta-invite',
            element: <MetaInvite />,
          },
          {
            path: 'onboarding',
            element: <Onboarding />,
          },
          {
            element: <ProtectedRoute requiredRole="NORMAL" />,
            children: [
              {
                path: ':email',
                element: <Settings />,
              },
            ],
          },
        ],
      },
      {
        path: '/admin',
        element: <ProtectedRoute requiredRole="ADMIN" />,
        children: [
          {
            path: 'hello',
            element: <Hello />,
          },
          {
            path: 'conversation-type/create',
            element: <CreateEditConversationType />,
          },
          {
            path: 'app-config',
            element: <AppConfig />,
          },
          {
            path: 'conversation-type/:id/config',
            element: <ConfigConversationType />,
          },
          {
            path: 'conversation-type/:id/edit',
            element: <CreateEditConversationType />,
          },
          {
            path: 'conversation-type/:id/contacts',
            element: <ContactsFilter />,
          },
          {
            path: 'conversation-type/list',
            element: <ConversationTypesList />,
          },
          {
            path: 'pricing/conversation',
            element: <ConversationCalculator />,
          },
          {
            path: 'call',
            element: <Call />,
          },
          {
            path: 'messaging-accounts',
            element: <Outlet />,
            children: [
              {
                path: 'list',
                element: <MessagingAccountsList />,
              },
              {
                path: 'create',
                element: <CreateEditMessagingAccount />,
              },
              {
                path: ':id/edit',
                element: <CreateEditMessagingAccount />,
              },
            ],
          },
          {
            path: 'user-messaging-accounts',
            element: <Outlet />,
            children: [
              {
                path: 'list',
                element: <UserMessagingAccountsList />,
              },
              {
                path: 'create',
                element: <CreateUserMessagingAccount />,
              },
            ],
          },
          {
            path: 'webhook-simulation-tool',
            element: <WebhookSimulationTool />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  async function setUserSession() {
    try {
      const { data, status } = await axios.post('/api/user/get-session');
      if (status === 200 && data.session.email) {
        dispatch(login({ email: data.session.email, role: data.session.role }));
      }
    } catch (error) {
      dispatch(logout());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setUserSession();
  }, []);

  if (loading) return <Loader />;

  return <RouterProvider router={router} />;
}

export default App;
