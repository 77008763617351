/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { truncateText } from '../../../utils/string.js';
import { useCreateNotification } from '../../../utils/toast.js';

const MAX_TEXT_LENGTH = 200;

export default function ConversationTypesList() {
  const [conversationTypes, setConversationTypes] = useState([]);

  const createNotification = useCreateNotification();
  const navigate = useNavigate();

  async function fetchConversationTypes() {
    try {
      const { data } = await axios.get('/api/conversation-type/listt');
      setConversationTypes(data);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  useEffect(() => {
    fetchConversationTypes();
  }, []);

  async function deleteConversationType(id) {
    try {
      await axios.post(`/api/admin/conversation-type/${id}/delete`);
      setConversationTypes(
        conversationTypes.filter(
          (conversationType) => conversationType.id !== id,
        ),
      );
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  async function duplicateConversationType(id) {
    try {
      const originalConversationType = conversationTypes.find(
        (conv) => conv.id === id,
      );

      if (!originalConversationType) {
        throw new Error('Conversation type not found');
      }

      // Create a new object without the id and prepare the conversation_phases
      const {
        id: _,
        conversation_phases,
        title,
        ...newConversationType
      } = originalConversationType;

      // Prepare the conversation_phases in the correct format
      if (conversation_phases && conversation_phases.length > 0) {
        newConversationType.conversation_phases = {
          create: conversation_phases.map(
            ({ prompt, index, transitionPrompt }) => ({
              transitionPrompt,
              prompt,
              index,
            }),
          ),
        };
      }

      newConversationType.title = `[D] ${title ?? ''}`;
      // Get the config from the original conversation type
      const { data: conversation } = await axios.get(
        `/api/conversation-type/${id}`,
      );
      newConversationType.config = conversation.config;

      const { data } = await axios.post(
        '/api/admin/conversation-type/create-edit',
        newConversationType,
      );

      const newId = data.conversationType.id;
      createNotification({
        message: 'Conversation type duplicated successfully',
        type: 'success',
      });
      navigate(`/admin/conversation-type/${newId}/edit`);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Conversation Types</h1>
      <Link
        to="/admin/conversation-type/create"
        className="btn btn-primary mb-3"
      >
        Create Conversation Type
      </Link>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Prompt / Title</th>
            <th>Actions</th>
            <th>Contacts</th>
          </tr>
        </thead>
        <tbody>
          {conversationTypes.map((conversationType) => (
            <tr key={conversationType.id}>
              <td>{conversationType.id}</td>
              <td>
                {truncateText(
                  conversationType.title || conversationType.rolePrompt,
                  MAX_TEXT_LENGTH,
                )}
              </td>
              <td>
                <div className="btn-group" role="group">
                  <Link
                    to={`/admin/conversation-type/${conversationType.id}/edit`}
                    className="btn btn-warning btn-sm"
                  >
                    Edit
                  </Link>
                  <Link
                    to={`/admin/conversation-type/${conversationType.id}/config`}
                    className="btn btn-info btn-sm"
                  >
                    Config
                  </Link>
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() =>
                      duplicateConversationType(conversationType.id)
                    }
                  >
                    Duplicate
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteConversationType(conversationType.id)}
                  >
                    Delete
                  </button>
                </div>
              </td>
              <td>
                {conversationType.config === 'GHL' && (
                  <Link
                    to={
                      '/admin/conversation-type/' +
                      `${conversationType.id}/contacts`
                    }
                    className="btn btn-secondary btn-sm"
                  >
                    GHL
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
