export default function ConfigField({
  title,
  value,
  onChange,
  onSave,
  isSubmitting,
  placeholder = '',
}) {
  return (
    <div className="card-body">
      <h5 className="card-title mt-4">{title}</h5>
      <div className="mb-3">
        <textarea
          className="form-control"
          value={value}
          onChange={onChange}
          rows="10"
          placeholder={placeholder}
        />
      </div>
      <button
        onClick={onSave}
        className="btn btn-primary"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Saving...' : `Save ${title}`}
      </button>
    </div>
  );
}
