import React from 'react';

import ConversationTypeSelector from './ConversationTypeSelector.jsx';

function ConversationListFilter({
  filters,
  handleFilterChange,
  handleConversationTypeSelect,
  handleLimitChange,
  limit,
}) {
  return (
    <>
      <div className="row mb-4">
        <div className="col-md-3">
          <input
            className="form-control"
            name="uuid"
            placeholder="Conversation UUID"
            value={filters.uuid}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col-md-3">
          <input
            className="form-control"
            name="prospectUuid"
            placeholder="Prospect UUID"
            value={filters.prospectUuid}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col-md-3">
          <ConversationTypeSelector onSelect={handleConversationTypeSelect} />
        </div>
        <div className="col-md-3">
          <input
            className="form-control"
            name="minRating"
            type="number"
            placeholder="Min Rating"
            value={filters.minRating}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-3">
          <input
            className="form-control"
            name="maxRating"
            type="number"
            placeholder="Max Rating"
            value={filters.maxRating}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col-md-3">
          <input
            className="form-control"
            name="minRecordingDuration"
            type="number"
            placeholder="Min Duration (seconds)"
            value={filters.minRecordingDuration}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col-md-3">
          <input
            className="form-control"
            name="maxRecordingDuration"
            type="number"
            placeholder="Max Duration (seconds)"
            value={filters.maxRecordingDuration}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col-md-3">
          <select
            className="form-select"
            value={limit}
            onChange={handleLimitChange}
          >
            <option value={10}>10 per page</option>
            <option value={50}>50 per page</option>
            <option value={100}>100 per page</option>
          </select>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-3">
          <select
            className="form-select"
            name="hasRecording"
            value={filters.hasRecording}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            <option value="true">Has Recording</option>
            <option value="false">No Recording</option>
          </select>
        </div>
        <div className="col-md-3">
          <select
            className="form-select"
            name="orderBy"
            value={filters.orderBy}
            onChange={handleFilterChange}
          >
            <option value="latestMessageDate">Latest Message Date</option>
            <option value="latestConversations">Latest Conversations</option>
          </select>
        </div>
      </div>
    </>
  );
}

export default ConversationListFilter;
