import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function Onboarding() {
  const [onboardingHTML, setOnboardingHTML] = useState('');

  useEffect(() => {
    axios.get('/api/app-config?key=onboardingHTML').then((res) => {
      setOnboardingHTML(res.data.values?.[0]?.value ?? '');
    });
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: onboardingHTML }} />;
}
