import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCreateNotification } from '../../utils/toast.js';

export default function ConnectMetaPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const createNotification = useCreateNotification();
  const [status, setStatus] = useState('Processing...');
  const email = useSelector((state) => state.auth.email);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    // DEVNOTE: For some reason, IG doesn't support params but FB does
    const type = params.get('type') || 'INSTAGRAM';
    if (code) {
      handleMetaPageConnection(code, type);
    } else {
      setStatus('Error: Code not found');
    }
  }, [location]);

  async function handleMetaPageConnection(code, type) {
    try {
      const { data } = await axios.post(
        '/api/meta-api/connect-meta-page',
        { code },
        { params: { type } },
      );
      setStatus(`Connected to ${data.pageName} successfully!`);
      setTimeout(() => navigate(`/user/${email}`), 3000);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
      setStatus(`Error connecting ${type} account`);
    }
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Authentication</h2>
          <p className="card-text">{status}</p>
        </div>
      </div>
    </div>
  );
}
