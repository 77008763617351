import axios from 'axios';
import React, { useState } from 'react';

import { adjustTextAreaHeight } from '../../utils/textInput.js';
import { useCreateNotification } from '../../utils/toast.js';

export default function ConversationMessages({
  conversation,
  hasAdminControls,
  onUpdate,
  messagesEndRef,
  selectedMessages,
  setSelectedMessages,
}) {
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingContent, setEditingContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createNotification = useCreateNotification();

  async function handleSaveEdit(messageId) {
    if (!editingContent.trim() || isSubmitting) return;

    setIsSubmitting(true);
    try {
      await axios.patch(
        `/api/conversation/${conversation.id}/message/${messageId}`,
        {
          message: editingContent.trim(),
        },
      );

      setEditingMessageId(null);
      setEditingContent('');
      onUpdate();

      createNotification({
        message: 'Message updated successfully',
        type: 'success',
      });
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  function toggleMessageSelection(messageId) {
    setSelectedMessages((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(messageId)) {
        newSelected.delete(messageId);
      } else {
        newSelected.add(messageId);
      }
      return newSelected;
    });
  }

  const classMappings = {
    SYSTEM: {
      text: 'text-center',
      bg: 'bg-warning text-dark',
      textColor: 'text-dark',
      btn: 'btn-light',
      btnDanger: 'btn-danger',
    },
    PROSPECT: {
      text: 'text-end',
      bg: 'bg-primary text-white',
      textColor: 'text-white',
      btn: 'btn-light',
      btnDanger: 'btn-danger',
    },
    AGENT: {
      text: 'text-start',
      bg: 'bg-light',
      textColor: 'text-muted',
      btn: 'btn-outline-secondary',
      btnDanger: 'btn-outline-danger',
    },
  };

  function getClass(role, type) {
    let effectiveRole = role;

    // Reverse roles if this is a live conversation
    if (conversation.prospectId) {
      if (role === 'AGENT') {
        effectiveRole = 'PROSPECT';
      } else if (role === 'PROSPECT') {
        effectiveRole = 'AGENT';
      }
    }

    return classMappings[effectiveRole][type];
  }

  return (
    <div className="card-body flex-grow-1 overflow-auto p-4">
      {conversation.messages.map((message) => (
        <div
          key={message.id}
          className={`mb-4 ${getClass(message.role, 'text')}`}
        >
          <div
            className={`d-inline-block p-3 rounded ${getClass(
              message.role,
              'bg',
            )}`}
          >
            {editingMessageId === message.id ? (
              <div className="mb-2">
                <textarea
                  className="form-control mb-2"
                  value={editingContent}
                  onChange={(e) => {
                    setEditingContent(e.target.value);
                    adjustTextAreaHeight(e.target);
                  }}
                  onFocus={(e) => adjustTextAreaHeight(e.target)}
                  rows="1"
                  autoFocus
                  style={{ whiteSpace: 'pre-wrap' }}
                />
                <div className="btn-group">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => handleSaveEdit(message.id)}
                  >
                    <i className="bi bi-check"></i> Save
                  </button>
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => setEditingMessageId(null)}
                  >
                    <i className="bi bi-x"></i> Cancel
                  </button>
                </div>
              </div>
            ) : (
              <>
                <p className="mb-1" style={{ whiteSpace: 'pre-wrap' }}>
                  {message.message}
                </p>
                <small className={getClass(message.role, 'textColor')}>
                  {new Date(message.dateSent).toLocaleString()}
                </small>
                {hasAdminControls && (
                  <div className="mt-2">
                    <button
                      className={`btn btn-sm me-2 ${getClass(
                        message.role,
                        'btn',
                      )}`}
                      onClick={() => {
                        setEditingMessageId(message.id);
                        setEditingContent(message.message);
                      }}
                    >
                      <i className="bi bi-pencil"></i>
                    </button>
                    <label
                      className={`btn btn-sm ${getClass(
                        message.role,
                        'btnDanger',
                      )}`}
                    >
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={selectedMessages.has(message.id)}
                        onChange={() => toggleMessageSelection(message.id)}
                      />
                      <i className="bi bi-trash"></i>
                    </label>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}
