import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { truncateText } from '../../utils/string.js';
import { useCreateNotification } from '../../utils/toast.js';
import CustomCard from '../utils/CustomCard.jsx';
import ConversationTypeSelector from './ConversationTypeSelector.jsx';

export default function ConversationAdminControls({
  conversation,
  onUpdate,
  selectedMessages,
  setSelectedMessages,
}) {
  const [newPhase, setNewPhase] = useState(conversation.phase || 0);
  const [conversationTypeId, setConversationTypeId] = useState(
    conversation.conversationType,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createNotification = useCreateNotification();
  const role = useSelector((state) => state.auth.role);
  const navigate = useNavigate();

  useEffect(() => {
    setNewPhase(conversation.phase);
  }, [conversation]);

  async function onMessageHistoryRebuild() {
    if (isSubmitting) return;

    if (!confirm('Are you sure you want to rebuild the message history?')) {
      return;
    }

    setIsSubmitting(true);

    try {
      await axios.post(
        `/api/admin/conversation/${conversation.id}/messages/rebuild`,
      );
      onUpdate();
      createNotification({
        message: 'Message history rebuilt successfully',
        type: 'success',
      });
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onEditConversation(payload) {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      await axios.patch(`/api/conversation/${conversation.id}`, payload);
      onUpdate();
      createNotification({ message: 'Phase updated', type: 'success' });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onDeleteConversation() {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      await axios.delete(`/api/conversation/${conversation.id}`);
      createNotification({ message: 'Conversation deleted', type: 'success' });
      navigate('/conversation/new');
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onDeleteMessages() {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const messageIds = Array.from(selectedMessages);
      await axios.delete(`/api/conversation/${conversation.id}/messages`, {
        data: { messageIds },
      });
      onUpdate();
      setSelectedMessages(new Set());
      createNotification({ message: 'Messages deleted', type: 'success' });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <CustomCard width={8}>
      <label className="form-label">Admin Controls</label>
      <div className="mt-2 mb-2 w-100">
        {role === 'ADMIN' && conversation.Prospect && (
          <button
            className="btn btn-outline-info btn-sm w-100 mb-3"
            onClick={() => onMessageHistoryRebuild(conversation.prospectId)}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="spinner-border spinner-border-sm me-2" />
            ) : (
              <i className="bi bi-clock-history me-2"></i>
            )}
            {isSubmitting ? 'Rebuilding History...' : 'Rebuild Message History'}
          </button>
        )}
        <label className="form-label">Edit Phase:</label>
        <select
          className="form-select"
          value={newPhase}
          onChange={(e) => setNewPhase(Number(e.target.value))}
        >
          <option value={-2}>Phase -2: NEEDS HUMAN INTERVENTION</option>
          <option value={-1}>Phase -1: ERRORED</option>
          {conversation.ConversationType.conversation_phases.map((phase) => (
            <option key={phase.index} value={phase.index}>
              Phase {phase.index}: {truncateText(phase.prompt, 50)}
            </option>
          ))}
          <option
            value={conversation.ConversationType.conversation_phases.length}
          >
            Phase {conversation.ConversationType.conversation_phases.length}:
            END_CONVERSATION
          </option>
        </select>
        <button
          className="btn btn-outline-primary btn-sm w-100 mt-2"
          onClick={() => onEditConversation({ phase: newPhase })}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm me-2" />
          ) : (
            <i className="bi bi-pencil me-2"></i>
          )}
          Update Phase
        </button>
        <div className="mt-3">
          <label className="form-label">Edit Conversation Type:</label>
          <ConversationTypeSelector
            value={conversationTypeId}
            setSelectedConversation={({ id }) => setConversationTypeId(id)}
          />
          <button
            className="btn btn-outline-primary btn-sm w-100 mt-2"
            onClick={() =>
              onEditConversation({ conversationType: conversationTypeId })
            }
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="spinner-border spinner-border-sm me-2" />
            ) : (
              <i className="bi bi-pencil me-2"></i>
            )}
            Update Conversation Type
          </button>
        </div>
        <div className="mt-3">
          <label className="form-label">Edit Conversation:</label>
          <button
            className="btn btn-outline-danger btn-sm w-100 mb-2"
            onClick={onDeleteConversation}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="spinner-border spinner-border-sm me-2" />
            ) : (
              <i className="bi bi-trash me-2"></i>
            )}
            Delete Conversation
          </button>
          <div className="mt-2 d-flex align-items-center">
            <label className="btn btn-outline-primary btn-sm me-2 flex-grow-1">
              <input
                className="me-2"
                type="checkbox"
                checked={selectedMessages.size === conversation.messages.length}
                onClick={(e) =>
                  e.target.checked
                    ? setSelectedMessages(
                        new Set(
                          conversation.messages.map((message) => message.id),
                        ),
                      )
                    : setSelectedMessages(new Set())
                }
              />
              Select all
            </label>
            <button
              className="btn btn-outline-warning btn-sm flex-grow-1"
              onClick={onDeleteMessages}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="spinner-border spinner-border-sm me-2" />
              ) : (
                <i className="bi bi-trash me-2"></i>
              )}
              Delete Messages ({selectedMessages.size})
            </button>
          </div>
        </div>
        <div className="mt-2">
          <small className="text-danger" style={{ fontSize: '0.7rem' }}>
            <i className="bi bi-exclamation-triangle me-1"></i>
            Important: Message edits/deletions only update our database - they
            will NOT unsend messages in{' '}
            {conversation.Prospect?.messagingAccount?.type ||
              'external platforms'}
          </small>
        </div>
      </div>
    </CustomCard>
  );
}
