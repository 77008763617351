import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function ProspectPreview({ prospect }) {
  const [modifiedUuid, setModifiedUuid] = useState(prospect?.uuid || 'N/A');

  async function getMetaPageName(pageId, accessToken, type) {
    try {
      const { data } = await axios.get('/api/meta-api/page-name', {
        params: { pageId, accessToken, type },
      });
      if (data.name) {
        setModifiedUuid(`${prospect.uuid}(${data.name})`);
      }
    } catch (error) {
      // error ignored
    }
  }

  useEffect(() => {
    if (
      prospect?.messagingAccount?.type === 'INSTAGRAM' ||
      prospect?.messagingAccount?.type === 'FACEBOOK'
    ) {
      getMetaPageName(
        prospect.uuid,
        prospect.messagingAccount.accessToken,
        prospect.messagingAccount.type,
      );
    }
  }, [prospect]);

  return <span>{modifiedUuid}</span>;
}
