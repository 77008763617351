import React from 'react';
import { Link } from 'react-router-dom';

import { truncateText } from '../../utils/string.js';
import ProspectPreview from './ProspectPreview.jsx';

const MAX_TEXT_LENGTH = 30;

export default function ConversationListTable({ conversations }) {
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>UUID</th>
            <th>Type</th>
            <th>Rating</th>
            <th>Phase</th>
            <th>Recording Duration</th>
            <th>Recording URL</th>
            <th>Messages</th>
            <th>Last Message Date</th>
            <th>Prospect UUID</th>
            <th>Messaging Account</th>
          </tr>
        </thead>
        <tbody>
          {conversations.map((conversation) => (
            <tr key={conversation.uuid}>
              <td>
                <Link to={`/conversation/${conversation.uuid}`}>
                  {conversation.uuid}
                </Link>
              </td>
              <td>
                {conversation.conversationType ? (
                  <Link
                    to={
                      '/admin/conversation-type/' +
                      `${conversation.conversationType}/edit`
                    }
                  >
                    {truncateText(
                      conversation.ConversationType.title ??
                        conversation.ConversationType.rolePrompt,
                      MAX_TEXT_LENGTH,
                    )}
                  </Link>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{conversation.rating}</td>
              <td>{conversation.phase}</td>
              <td>{conversation.recordingDuration}</td>
              <td>
                {conversation.recordingUrl ? (
                  <a
                    href={conversation.recordingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Listen
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{conversation.messagesCount}</td>
              <td>
                {conversation.lastMessageDate
                  ? new Date(conversation.lastMessageDate).toLocaleString()
                  : 'N/A'}
              </td>
              <td>
                <ProspectPreview prospect={conversation.Prospect} />
              </td>
              <td>
                {conversation.Prospect?.messagingAccount?.pageName ?? 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
