import React from 'react';
import { Link } from 'react-router-dom';

function Hello() {
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header">
          <h1 className="card-title">Admin Actions</h1>
        </div>
        <div className="card-body">
          <ul className="list-group">
            <li className="list-group-item">
              <Link to="/admin/app-config" className="text-decoration-none">
                App Config
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/admin/conversation-type/create"
                className="text-decoration-none"
              >
                Create Conversation Type
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/admin/conversation-type/list"
                className="text-decoration-none"
              >
                List Conversation Types
              </Link>
            </li>
            <li className="list-group-item">
              <Link to="/conversation/list" className="text-decoration-none">
                List Conversations
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/admin/messaging-accounts/list"
                className="text-decoration-none"
              >
                Messaging Accounts
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/admin/user-messaging-accounts/list"
                className="text-decoration-none"
              >
                User Messaging Account Permissions
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/admin/pricing/conversation"
                className="text-decoration-none"
              >
                Pricing Tool
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                to="/admin/webhook-simulation-tool"
                className="text-decoration-none"
              >
                Webhook
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Hello;
