import axios from 'axios';

export async function getSupportMessage() {
  const defaultMessage = 'Something went wrong! Please contact support.';
  let message = defaultMessage;

  try {
    const { data } = await axios.get('/api/app-config?key=supportMessage');
    message = data.values?.[0]?.value || defaultMessage;
  } catch {
    // skipped error logging
  }

  return message;
}
