import axios from 'axios';
import React, { useEffect, useState } from 'react';

import ConfigField from '../../components/app-config/Field.jsx';
import { useCreateNotification } from '../../utils/toast.js';

const CONFIG_FIELDS = [
  {
    key: 'registerHTML',
    title: 'Register HTML',
    placeholder: 'Enter custom HTML for register page...',
  },
  {
    key: 'supportMessage',
    title: 'Support Message',
    placeholder: 'Enter custom support message...',
  },
  {
    key: 'onboardingHTML',
    title: 'Onboarding HTML',
    placeholder: 'Enter custom onboarding HTML...',
  },
];

export default function AppConfig() {
  const [appConfig, setAppConfig] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const createNotification = useCreateNotification();

  async function fetchAppConfig() {
    try {
      const { data } = await axios.get('/api/app-config');
      const configObj = {};
      data.values.forEach((item) => {
        configObj[item.key] = item.value;
      });
      setAppConfig(configObj);
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    }
  }

  async function handleSubmit(key, value) {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const { data } = await axios.post('/api/admin/app-config', {
        key,
        value,
      });
      createNotification({
        message: data.message,
        type: 'success',
      });
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    fetchAppConfig();
  }, []);

  return (
    <div className="container mt-5">
      <div className="card">
        {CONFIG_FIELDS.map(({ key, title, placeholder }) => (
          <ConfigField
            key={key}
            title={title}
            value={appConfig[key]}
            onChange={(e) =>
              setAppConfig({ ...appConfig, [key]: e.target.value })
            }
            onSave={() => handleSubmit(key, appConfig[key])}
            isSubmitting={isSubmitting}
            placeholder={placeholder}
          />
        ))}
      </div>
    </div>
  );
}
