import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomCard from '../../../components/utils/CustomCard.jsx';
import { useCreateNotification } from '../../../utils/toast.js';

export default function CreateUserMessagingAccount() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userEmail: '',
    messagingAccountId: '',
  });
  const [messagingAccounts, setMessagingAccounts] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const createNotification = useCreateNotification();

  async function getMessagingAccountsList() {
    try {
      const { data } = await axios.get('/api/admin/messaging-accounts/list');
      setMessagingAccounts(data);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  useEffect(() => {
    getMessagingAccountsList();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    try {
      const payload = {
        userEmail: formData.userEmail,
        messagingAccountId: Number(formData.messagingAccountId),
      };
      const response = await axios.post(
        '/api/admin/user-messaging-accounts/create',
        payload,
      );
      navigate('/admin/user-messaging-accounts/list');
      createNotification({
        message: response.data.message,
        type: 'success',
      });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <CustomCard width={6}>
      <h3 className="mb-4">Create User Messaging Account Permissions</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">User Email</label>
          <input
            type="email"
            name="userEmail"
            className="form-control"
            value={formData.userEmail}
            onChange={(e) =>
              setFormData({ ...formData, userEmail: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Messaging Account</label>
          <select
            name="messagingAccountId"
            className="form-select"
            value={formData.messagingAccountId}
            onChange={(e) =>
              setFormData({ ...formData, messagingAccountId: e.target.value })
            }
            required
          >
            <option value="">Select Messaging Account</option>
            {messagingAccounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.pageName}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={submitting}
          >
            {submitting ? 'Saving...' : 'Save'}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate('/admin/user-messaging-accounts/list')}
          >
            Cancel
          </button>
        </div>
      </form>
    </CustomCard>
  );
}
