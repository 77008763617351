import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../../components/utils/Loader.jsx';
import { useCreateNotification } from '../../../utils/toast.js';

export default function UserMessagingAccountsList() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const createNotification = useCreateNotification();

  async function getUserMessagingAccountsList() {
    try {
      const { data } = await axios.get(
        '/api/admin/user-messaging-accounts/list',
      );
      setAccounts(data);
    } catch (error) {
      createNotification({ message: error.message, type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUserMessagingAccountsList();
  }, []);

  async function deleteAccount(id) {
    if (
      window.confirm(
        'Are you sure you want to delete ' +
          "the user's permission to this account?",
      )
    ) {
      try {
        await axios.delete(`/api/admin/user-messaging-accounts/${id}`);
        setAccounts(accounts.filter((account) => account.id !== id));
        createNotification({
          message: 'User Messaging Account deleted successfully',
          type: 'success',
        });
      } catch (error) {
        createNotification({ message: error.message, type: 'error' });
      }
    }
  }

  if (loading) return <Loader />;

  return (
    <div className="container mt-5">
      <h1 className="mb-4">User Messaging Account Permissions</h1>
      <p className="mb-4">
        This table shows the permissions that users have to various messaging
        accounts.
      </p>
      <Link
        to="/admin/user-messaging-accounts/create"
        className="btn btn-primary mb-3"
      >
        Create User Messaging Account Permission
      </Link>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>User</th>
            <th>Messaging Account</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <tr key={account.id}>
              <td>{account.id}</td>
              <td>
                <Link to={`/user/${account.user.email}`}>
                  {account.user.email}
                </Link>
              </td>
              <td>
                <Link
                  to={
                    '/admin/messaging-accounts' +
                    `/${account.messagingAccountId}/edit`
                  }
                >
                  {account.messagingAccount.pageName}
                </Link>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteAccount(account.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
