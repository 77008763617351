import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomCard from '../../components/utils/CustomCard.jsx';
import CustomInput from '../../components/utils/CustomInput.jsx';
import { login } from '../../features/auth.js';
import { getSupportMessage } from '../../utils/appConfig.js';
import { useCreateNotification } from '../../utils/toast.js';

const RETRY_DELAY_SECONDS = 2;
const MAX_RETRIES = 5;

export default function SetPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createNotification = useCreateNotification();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setEmail(searchParams.get('email'));
  }, []);

  function validateForm() {
    if (!password || password.length < 8) {
      createNotification({
        message: 'Password must be at least 8 characters long',
        type: 'error',
      });
      return false;
    }
    if (!confirmPassword || password !== confirmPassword) {
      createNotification({
        message: 'Passwords do not match',
        type: 'error',
      });
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isSubmitting) return;
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      for (let i = 0; i <= MAX_RETRIES; ++i) {
        const { data, status } = await axios.post('/api/user/set-password', {
          email,
          password,
        });

        if (status === 202 && i < MAX_RETRIES) {
          await new Promise((resolve) =>
            setTimeout(resolve, RETRY_DELAY_SECONDS * 1000),
          );
          continue;
        }

        if (status === 202) {
          const supportMessage = await getSupportMessage();
          throw supportMessage;
        }

        createNotification({
          message: data.message,
          type: 'success',
        });

        dispatch(login({ email: data.session.email, role: data.session.role }));
        navigate('/user/onboarding', { replace: true });
        break;
      }
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <CustomCard width={4}>
      <form onSubmit={handleSubmit}>
        <CustomInput
          label="Password"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CustomInput
          label="Confirm Password"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary mt-3 mb-4 w-100"
        >
          Set a new password for your account
        </button>
      </form>
    </CustomCard>
  );
}
