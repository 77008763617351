import axios from 'axios';
import { useState } from 'react';

import { useCreateNotification } from '../../utils/toast.js';

export default function CreateEditConversationPhase({
  conversationType,
  conversationPhase,
  getConversationPhases,
  setCreatePhaseVisible,
}) {
  const [phase, setPhase] = useState(conversationPhase);
  const createNotification = useCreateNotification();

  async function save() {
    const data = {
      ...phase,
      conversationType: conversationType.id,
    };
    try {
      await axios.post('/api/admin/conversation-phase/create-edit', data);

      await getConversationPhases();
      setCreatePhaseVisible(false);

      createNotification({
        message: 'Phase saved successfully!',
        type: 'success',
      });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  async function deletePhase() {
    if (window.confirm('Delete this phase?')) {
      try {
        await axios.delete(`/api/admin/conversation-phase/${phase.id}`);
        createNotification({
          message: 'Phase deleted successfully',
          type: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        createNotification({ message: error, type: 'error' });
      }
    }
  }

  return (
    <>
      <tr>
        <td>
          <input
            type="text"
            value={phase.index}
            className="form-control text-center"
            onChange={(e) =>
              setPhase({ ...phase, index: parseInt(e.target.value) })
            }
          />
        </td>
        <td>
          <textarea
            value={phase.prompt}
            className="form-control"
            onChange={(e) => setPhase({ ...phase, prompt: e.target.value })}
            rows="15"
          />
        </td>
        <td>
          <textarea
            value={phase.transitionPrompt}
            className="form-control"
            onChange={(e) =>
              setPhase({ ...phase, transitionPrompt: e.target.value })
            }
            rows="15"
          />
        </td>
        <td>
          <div className="d-flex flex-column gap-2">
            <button className="btn btn-sm btn-primary" onClick={save}>
              Save
            </button>
            <button
              className="btn btn-sm btn-outline-danger d-flex flex-column
                align-items-center px-0"
              onClick={deletePhase}
            >
              <div className="d-flex flex-column align-items-center gap-0">
                <i className="bi bi-trash"></i>
                <span className="text-nowrap">Delete</span>
              </div>
            </button>
          </div>
        </td>
      </tr>
      <tr className="border-bottom bg-light">
        <td className="text-center align-middle fw-bold">
          Follow Up Prompt (optional):
        </td>
        <td colSpan="3">
          <textarea
            value={phase.followUpPrompt}
            className="form-control"
            onChange={(e) =>
              setPhase({ ...phase, followUpPrompt: e.target.value })
            }
            rows="15"
          />
        </td>
      </tr>
    </>
  );
}
